import { List, ListItem, ListItemText, Button } from '@material-ui/core';
import {Container, Row, Col} from 'react-bootstrap';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import { TextField } from '@material-ui/core';
import Divider from '@mui/material/Divider';

import Avatar from '@mui/material/Avatar';

import Badge from '@mui/material/Badge';

const FormPreview = (props) => {
 
  const {title, email, firstname, lastname, tel, addressAuto, website, position,
    keywords, additionalTitle, additionalLinks, activities, 
    fieldsOfResearch, publicEmail} = props.entries;
  
  const handleSubmit = (event) => {
    event.preventDefault();
    props.handleSubmit();
  }

  return (
    <>
      <Container fluid="md" className="entry-form-summary">
        { <Badge badgeContent={ props.entries.formNumber + "/4"} style={{float: 'right'}} color="warning" />}
        <List>
        <Row>
          <Col>
            <ListItem>
              <ListItemText primary="Foto" />
                <Avatar src={props.entries.image} 
                alt={""}
              />      
            </ListItem>
          </Col>
          <Divider />
        </Row>
        <Row>
          <Col>
            <ListItem>
              <ListItemText primary="Position" secondary={position}/>
            </ListItem>
          </Col>
          <Col>
            <ListItem>
              <ListItemText primary="Titel" secondary={title.length ? title : '--'}/>
            </ListItem>
          </Col>
          <Col>
            <ListItem>
              <ListItemText primary="Titelzusatz" secondary={additionalTitle.length ? additionalTitle : '--'}/>
            </ListItem>
          </Col>
          <Divider />
        </Row>
        <Row>
          <Col>
            <ListItem>
              <ListItemText primary="Vorname" secondary={firstname}/>
            </ListItem>
          </Col>
          <Col>
            <ListItem>
              <ListItemText primary="Nachname" secondary={lastname}/>
            </ListItem>
          </Col>
          <Divider />
        </Row>
        <Row>
          <Col>
            <ListItem>
              <ListItemText primary="Benutzername/E-Mail" secondary={email}/>
            </ListItem>
          </Col>
          <Divider />
        </Row>
        <Row>
          <Col>
            <ListItem>
              <ListItemText primary="Kontakt E-Mail" secondary={publicEmail}/>
            </ListItem>
            </Col>
            <Col>
              <ListItem>
                <ListItemText primary="Tel" secondary={tel}/>
              </ListItem>
            </Col>
          <Divider />
        </Row>
        <Row>
          <Col>
            <ListItem>
              <ListItemText primary="Addresse" secondary={addressAuto.addressName}/>
            </ListItem>
          </Col>
          <Divider />
        </Row>
        <Row>
          <Col>
            <ListItem>
              <ListItemText primary="Website" secondary={website}/>
            </ListItem>
          </Col>
          <Divider />
        </Row>
        <Row>
          <Col>
            <ListItem>
              <ListItemText primary="Digitalisierungsbezogenen Aktivitäten" secondary={activities} style={{wordBreak: 'break-all'}}/>
            </ListItem>
          </Col>
          <Divider />
        </Row>
        <Row>
          <Col>
            <ListItem>
              <ListItemText primary="Forschungsgebiete/-interessen" secondary={fieldsOfResearch} style={{wordBreak: 'break-all'}}/>
            </ListItem>
          </Col>
          <Divider />
        </Row>
        <Row>
          <Col>
            <ListItem>
              <ListItemText primary="Links zu eigenen Produkten, Projekte oder weiteren Inhalten" secondary={additionalLinks} style={{wordBreak: 'break-all'}}/>
            </ListItem>
          </Col>
          <Divider /> 
        </Row>
        <Row>
          <Col>
            <ListItem>
              <ListItemText primary="Schlagworte" secondary={keywords && keywords.map(keyword => keyword).join(' | ')} style={{wordBreak: 'break-all'}}/>
            </ListItem>
            {/* honeybot to capture  bots and handle them  on serverside */}
            <TextField 
              name="work"
              placeholder="work"
              label="work"
              required
              variant="filled"
              style={{display: 'none'}}
              tabIndex={-1}
            />
          </Col>
        </Row>
        </List>
        <Row>
          <Col xs={12} sm={6}>
            <Button 
              onClick={ (event)=>{event.preventDefault(); props.previousForm();} }
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              <ArrowBackIcon sx={{marginRight: '1rem'}}/> Zurück
            </Button>
          </Col>
          <Col xs={12} sm={6}>
            <Button 
              onClick={handleSubmit  }
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              Abschicken <CheckIcon sx={{marginLeft: '1rem'}}/>
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default FormPreview;