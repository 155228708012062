import { useState } from 'react';
import { Navigate } from "react-router-dom";


import Navi from './subcomponents/Navi';
import Map from './subcomponents/Map'
import Footer from './subcomponents/Footer';

import {Button} from "react-bootstrap";
import Highlighter from "react-highlight-words";
import MapRoundedIcon from '@mui/icons-material/MapRounded';

const Intro = () => {
    // Homepage title, and list of its words to use it in higlighter, the same applies to page description.
    let title = "Netzwerk digitaler Chemieunterricht";
    let titleWords = title.split("\\s");
    
    //let description = "Eine App zur Vernetzung von Wissenschaftler*innen der Didakatik der Chemie in NRW";
    //let descriptionWords = description.split("\\n");

    let datenschutzLink = "https://www.uni-wuppertal.de/de/datenschutz/";
    let impressumLink = "https://www.uni-wuppertal.de/de/impressum/";

    // More details to show at the end of the footer on homepage.
    let furtherDetials = "";
    
    // By clicking on the intro-button, go to the map.
    let [goToMap, setGoToMap] = useState(false);

    let handleClickIntro = () =>{
        setGoToMap(true);
    }
   
    return ( 
        <>
            <div>
                <div>
                    <Navi currentActive={"home"}/>
                    <div className="intro-map-title-wrapper">
                        <Map className="intro-map" opacity={0.7} mapWidth = {"100%"} mapHeight={"100vh"}/>
                        <Highlighter
                            title="Netzwerk digitaler Chemieunterricht"
                            searchWords={titleWords}
                            autoEscape={false}
                            textToHighlight={title}
                            className="intro-title"
                            highlightStyle={{ backgroundColor: '#C0E3E4',  borderRadius: "0.4rem 1.4rem" }}
                        />                   
                        <Button id="intro-button" onClick={handleClickIntro} title="Karte zeigen">
                            Zur Karte
                            <MapRoundedIcon id="intro-map-icon" fontSize="large"  />
                        </Button>
                    </div>
                    {goToMap && <Navigate to="/karte" />}
                    <Footer furtherDetials={furtherDetials} datenschutz={datenschutzLink} 
                    impressum={impressumLink}/>
                </div>
            </div>  
        </>
    );
}

export default Intro;