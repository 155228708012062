import { useMap, MapContainer, TileLayer} from "react-leaflet";

const Animation = () => {
  const map = useMap();
  map.flyTo([51.2562,7.150], 9);
  return <></>
}

const Map = ({opacity}) => {
  
  return (
    <>
        {       
          <MapContainer center={[53.2562,9.150]} zoom={3} style={{height: "100vh"}}
            doubleClickZoom= {false} 
            closePopupOnClick= {false} 
            dragging= {true}
            zoomSnap= {false} 
            zoomDelta= {false} 
            trackResize= {false}
            touchZoom= {false}
            scrollWheelZoom= {false}
            zoomControl={false}
          >
          <Animation />
          <TileLayer
            url={`${process.env.REACT_APP_MAPTILE_BASE_URL}{z}/{x}/{y}.png`}
            attribution= {`&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors`}
            opacity={opacity} 
          />
        </MapContainer>
      }
    </>
  );
}
 
export default Map;