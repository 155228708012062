import {Container, Row, Col} from 'react-bootstrap';
import Avatar from '@mui/material/Avatar';
import {Typography } from '@material-ui/core';
import Divider from '@mui/material/Divider';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import InterestsOutlinedIcon from '@mui/icons-material/InterestsOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import Chip from '@mui/material/Chip';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import Badge from '@mui/material/Badge';

const BASE_URL = process.env.REACT_APP_NEDICHE_API;
const PHOTOS_DIR = process.env.REACT_APP_PHOTOS_DIR;

const Profile = ({user}) => {
  return (
    <>
      {user &&
        <Container className="full-profile">
          <Row>
            <Col sm={6} md={7}>
              <Typography  variant="h3" align="center">
                {user.firstname && user.firstname} {user.lastname && user.lastname}
                {user.position && <p>{user.position}</p>}
              </Typography>
              <Divider />
              {user.title && <Typography variant="h6" >
                <BadgeOutlinedIcon />
                {user.title &&  user.title} 
                {user.additionalTitle && `| ${user.additionalTitle}`} 
              </Typography>}
              {user.addressName && <Typography paragraph>
                <BusinessOutlinedIcon/>
                {user.addressName} 
              </Typography>}
              {user.publicEmail && <Typography paragraph>
                <EmailOutlinedIcon/>
                {user.publicEmail} 
              </Typography>}
              {user.tel && <Typography paragraph>
                <ContactPhoneOutlinedIcon/>
                {user.tel} 
              </Typography>}
              {user.website && <Typography paragraph>
                <LanguageOutlinedIcon/>
                {user.website} 
              </Typography>}
              {user.keywords && <Typography component={"div"} >
                <Badge sx={{mr:2}} badgeContent={user.keywords.length} color="secondary">
                <InterestsOutlinedIcon/>
                </Badge> 
                {user.keywords.map((k) => <Chip key={k} label={k}/>)} 
              </Typography>}
              {user.additionalLinks && <Typography paragraph>
                <strong>Links zu eigenen Produkten, Projekten oder weiteren Inhalten</strong><br />
                { user.additionalLinks}
              </Typography>}
              {user.fieldsOfResearch && <Typography paragraph>
                <strong>Forschungsgebiete und/oder Interessen</strong> <br />
                { user.fieldsOfResearch} 
              </Typography>}
            </Col>
            <Col sm={2} md={4}>
              <Avatar  
                alt={user.lastname && user.lastname[0].toUpperCase()}
                src={user.image && `${BASE_URL}/${PHOTOS_DIR}/${user.image}`}
              /> 
            </Col>
          </Row>
          <Row>
            {user.activities && <Typography paragraph>
              <strong>Digitalisierungsbezogene Aktivitäten</strong><br />
              {user.activities} 
            </Typography>}
          </Row>
        </Container>
      }
    </>
  );
}
 
export default Profile;