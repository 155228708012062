
import {Container, Row, Col} from 'react-bootstrap';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { ListItemText, Typography, TextField,Button } from '@material-ui/core';

import Avatar from '@mui/material/Avatar';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Badge from '@mui/material/Badge';
import Input from '@mui/material/Input';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import Chip from '@mui/material/Chip';

import {useState}  from 'react';

const BASE_URL = process.env.REACT_APP_NEDICHE_API +"/";
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};

const rePatternTel = /^\+?[0-9]{7,14}$/;

const FormC = (props) => {

  const [errorTel, setErrorTel] = useState(false);
  const handleKeywordsChange = (event) => {
    props.handleKeywordsChange(event);
  }

  return (
    <>
      <Container fluid="md" className="entry-form">
        {<Badge badgeContent={ props.entries.formNumber + "/4"} style={{float: 'right'}} color="warning" />}
        <Typography variant="h4">
          Kontaktdaten &  Interessen 
        </Typography>
        <Row>
          <Col sm={4}>    
            {(typeof props.entries.image !== 'object' && !props.entries.imageSrc) && <Chip label="Foto Entfernen" variant="outlined" style={{backgroundColor: "#ABCDEF"}} onClick={props.handleRemovePhoto}/>}
            { props.entries.imageSrc && <Chip label="Foto Entfernen" variant="outlined" style={{backgroundColor: "#ABCDEF"}} onClick={props.handleRemovePhoto}/>}
            <Avatar src={ (!props.entries.image || (typeof props.entries.image === "object" && props.entries.imageSrc !== "")) ? `${BASE_URL}uploads/${props.entries.imageSrc}`: 
              typeof props.entries.image === 'object' ? "" :
              props.entries.image ? props.entries.image :
              ""} 
              alt={""} />       
          </Col>
          <Col> 
            {/* [ 'jpg', 'jpeg', 'png' ] */}
            <label htmlFor="contained-button-file">
              <Input onChange={props.handleUpload} accept="image/*" id="contained-button-file" hidden type="file" />
              <FormHelperText>{"Erlaubte Formate fürs Foto:"} <strong>{"jpg, jpeg und png"}</strong></FormHelperText>
              <Button variant="contained" component="span" endIcon={<AddAPhotoIcon sx={{m: 1}}/>}>
                Hochladen
              </Button> 
            </label>
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField 
              name="publicEmail"
              placeholder="E-Mail öffentlich (optional)"
              label="E-Mail öffentlich (optional)"
              type="email"
              variant="filled"
              fullWidth
              onChange={props.handleChange}
              value={props.entries.publicEmail}
            />
          </Col>
          <Col sm={4}>
            <TextField 
              name="tel"
              placeholder="Tel. Nummer (optional)"
              label="Tel. Nummer (optional)"
              variant="filled"
              fullWidth
              onChange={(e)=>{ props.handleChange(e); e.target.value==="" ? setErrorTel(false) : setErrorTel(true); }}
              value={props.entries.tel}
              error={ errorTel && ( !rePatternTel.test(props.entries.tel.trim()) ) }
              helperText={ errorTel &&  (( !rePatternTel.test(props.entries.tel.trim())  
              && "Ungültige Telefonnummer")) }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField 
              name="website"
              placeholder="Webseite (optional)"
              label="Webseite (optional)"
              variant="filled"
              fullWidth
              onChange={props.handleChange}
              value={props.entries.website}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              id="activities"
              label="Digitalisierungsbezogene Aktivitäten"
              placeholder="Digitalisierungsbezogene Aktivitäten"
              variant="filled"
              multiline
              fullWidth
              name="activities"
              inputProps={{ maxLength: 500 }}
              maxRows={8}
              value={props.entries.activities}
              onChange={(e)=>{props.handleChange(e); }}
              helperText={ props.entries.activities && props.entries.activities.trim().length + " von 500" }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              id="fieldsOfResearch"
              variant="filled"
              placeholder="Forschungsgebiete/-interessen"
              label="Forschungsgebiete/-interessen"
              name="fieldsOfResearch"
              multiline
              fullWidth
              inputProps={{ maxLength: 500 }}
              maxRows={8}
              value={props.entries.fieldsOfResearch}
              onChange={props.handleChange}
              helperText={ props.entries.fieldsOfResearch && props.entries.fieldsOfResearch.trim().length + " von 500" }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              id="links"
              variant="filled"
              label="Links zu eigenen Produkten, Projekten oder weiteren Inhalten"
              placeholder="Links zu eigenen Produkten, Projekten oder weiteren Inhalten"
              multiline
              fullWidth
              name="additionalLinks"
              maxRows={8}
              // error={ props.entries.additionalLinks && props.entries.addtionalLinks.length+1 >= 500 }
              // helperText={"Max. Groesse ueberschritten"}
              inputProps={{ maxLength: 500 }}
              value={props.entries.additionalLinks}
              onChange={props.handleChange}
              helperText={ props.entries.additionalLinks && props.entries.additionalLinks.trim().length + " von 500" }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormControl variant="standard" style={{width: '100%'}}>
              <InputLabel id="from-keywords-label" required>Schlagworte</InputLabel>
              <Select
                multiple
                labelId="from-keywords-label"
                id="form-keywords"
                label="keywords"
                name="keywords"
                placeholder="Schlagworte"
                variant="filled"
                fullWidth
                onChange={handleKeywordsChange}
                value={props.entries.keywords}
                MenuProps={MenuProps}
                renderValue={(selected) => selected.join(', ')}
              >
                {props.keywordsList.map((name) => 
                  {return <MenuItem style={{width: '100%'}} key={name} value={name} divider> 
                    <Checkbox style={{marginRight: '1rem', marginLeft: '1rem'}}  checked={props.entries.keywords.indexOf(name) > -1} />
                    <ListItemText primary={name} />  <br />
                  </MenuItem>}
                )}
              </Select>
            </FormControl>
            {/*
              This Textfield is probably also used as a honeypot
            */}
            <TextField name="username" placeholder="username" onChange={props.handleChange} value={props.username} tabIndex={-1} style={{display: 'none', opacity: '0.1', maxWidth: "1px"}} />
          </Col>
        </Row>
        <Row>
          <Col >
            <Button 
              onClick={ (event)=>{event.preventDefault(); props.previousForm();} }
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            > 
              <ArrowBackIcon sx={{marginRight: '1rem'}}/> Zurück
            </Button>
          </Col>
          <Col>
            <Button 
              onClick={ (event)=>{event.preventDefault(); props.nextForm();}}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              >
                Weiter <ArrowForwardIcon sx={{marginLeft: '1rem'}}/>
            </Button>
          </Col>
        </Row>
    
      </Container>
    </>
  );
}
 
export default FormC;