
import ProfileCard from './ProfileCard';
import "../../App.css";
import {Container, Row, Col} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ButtonBase from '@mui/material/ButtonBase';

const Accounts = (props) => {
  let navigate = useNavigate();
    return ( 
      <Container className="profileCard">
        <Row> 
          {props.users && props.users      
          .map( 
            (user, index) => <Col key={index}>
              <ButtonBase style={{width: "100%"}}  onClick={()=>
                navigate(`/profile/${user.userId && user.userId}/${user.firstname && user.firstname}-${user.lastname &&user.lastname}`)
              }>
                <ProfileCard user={user} profiles={true} />   
              </ButtonBase>
            </Col> 
          )} 
        </Row>
    </Container>
  );
}
 
export default Accounts;