import { TextField, Button } from '@material-ui/core';
import {Col, Container, Row} from 'react-bootstrap';
import Alert from '@mui/material/Alert';

const LoginForm = (props) => {
  return (
    <>
      <Container className="adminLoginForm">
        <Row>
          <Col>
            { props.error &&  <Alert severity="error">
              {props.error}
            </Alert>}
            <TextField 
              name="email"
              placeholder="E-Mail / Benutzername"
              label="E-Mail / Benutzername"
              variant="filled"
              fullWidth
              onChange={props.handleEmail} 
              value={props.email}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField 
              name="password"
              placeholder="Passwort"
              type="password"
              label="Passwort"
              variant="filled"
              fullWidth
              onChange={props.handlePassword} 
              value={props.password}
              onKeyPress={(e)=>{e.key === "Enter" && props.handleSubmit(e);}}
            />
          </Col>
        </Row>
        <Button 
          onClick={ props.handleSubmit }
          type="submit"
          fullWidth
          variant="contained"
          color="primary"> Einloggen
        </Button>
      </Container>
    </>
  );
}
 
export default LoginForm;