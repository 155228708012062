import {useState, useEffect } from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import Alert from '@mui/material/Alert';

import {Typography, Button } from '@material-ui/core'

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormControlLabel from '@mui/material/FormControlLabel';
import AutoCompleteAddress from './AutocompleteAddress'

import Badge from '@mui/material/Badge';

import FormDialog from './SearchAddressDialog'

const FormB = (props) => {

  const [disableNext, setDisableNext] = useState(true);
  const {addressAuto} = props.entries;

  useEffect(()=>{
    addressAuto === null || Object.keys(addressAuto).length === 0 ? setDisableNext(true) : setDisableNext(false);
  }, [addressAuto]);

  return (
    <>
      <Container fluid="md" className="entry-form">
        {<Badge badgeContent={ props.entries.formNumber + "/4"} style={{float: 'right'}} color="warning" />}
        <Row>
          <Typography variant="h4">
            Adresse [Uni, ZfsL, Schule]
          </Typography>
          {props.searchAddressError && <Alert severity="error"> {props.searchAddressError} </Alert>}
        </Row>
        <Row>
          <Col>
            <AutoCompleteAddress data={props.addressData} selectedValue={props.entries.addressAuto}  handleChange={(value)=>{props.sendAddressAuto(value)}} />
          </Col>
          <Col>
            Sollten Sie Ihre Adresse nicht in der Liste finden, können Sie diese über die folgende Funktion suchen und hinzufügen.<br />
            Achtung: Dazu werden die Daten der zu suchenden Adresse sowie Ihre IP-Adresse an openstreetmap.org übermittelt, um Koordinaten für die Karte zu ermitteln.<br />
            <FormControlLabel label="" 
              control={
                <Button style={{marginLeft: "4%"}} variant="outlined" onClick={()=>{props.handleOpenSearchAddressDialog(true)}}>
                  Adresse Suchen
                </Button>
              } 
              labelPlacement="start"
            />
            <FormDialog 
              sendAddressUp= {props.sendAddressUp}  
              searchAddressError={props.searchAddressError}
              handleCloseDialog={()=>props.handleOpenSearchAddressDialog(false)} 
              openDialog={props.openDialog}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button 
              onClick={ (event)=>{event.preventDefault(); props.previousForm();} }
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            > 
              <ArrowBackIcon sx={{marginRight: '1rem'}}/> Zurück
            </Button>
          </Col>
          <Col>
            <Button 
              onClick={ (event)=>{event.preventDefault(); props.nextForm();}}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={disableNext}
             >
              Weiter <ArrowForwardIcon sx={{marginLeft: '1rem'}}/>
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default FormB;