import "../../App.css"

import {Navbar, Nav} from 'react-bootstrap';
import NeDiChe from "../../assets/logo.png"

// matrial icons
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';

const Navi = ({currentActive}) => {
  const logoSrc = NeDiChe;
  return ( 
    <>
      <Navbar expand="md">
        <Navbar.Brand href="/home">
            <img src={logoSrc} alt="NeDiChe Logo" title="Homepage" />
        </Navbar.Brand>
        <Navbar.Toggle title="Funktionen zeigen"/>
        <Navbar.Collapse>    
          <div style={{ width: "50%"}}></div>
          <Nav variant="tabs" className="w-100 nav-justified" defaultActiveKey={currentActive} >
            <Nav.Item>
              <Nav.Link eventKey= "home" title="Homepage" href="/home">
                <HomeRoundedIcon sx={{mr: 2}}/>
                  Startseite
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="karte" title="Karte Zeigen" href="/karte">
                <AddLocationAltOutlinedIcon sx={{mr: 2}}/>
                  Karte
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="profile" title="Profile ansehen" href="/profile">
                <ViewListRoundedIcon sx={{mr: 2}}/>
                  Profile
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="profil-aendern" title="Profil Ändern" href="/profil-aendern">
                <ManageAccountsRoundedIcon sx={{mr: 2}}/>
                  Ändern
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="profil-erstellen" title="Neues Profil anlegen" href="/profil-erstellen">
                <AddRoundedIcon sx={{mr: 1}}/>
                  Eintragen
                </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar> 
    </>
  );
}
         
export default Navi;