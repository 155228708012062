import Avatar from '@mui/material/Avatar';
import {  Typography } from '@material-ui/core'
import MailIcon from '@mui/icons-material/Mail';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import InterestsIcon from '@mui/icons-material/Interests';
import Badge from '@mui/material/Badge';
import Chip from '@mui/material/Chip';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import {Container} from 'react-bootstrap';

const BASE_URL = process.env.REACT_APP_NEDICHE_API;
const PHOTOS_DIR = process.env.REACT_APP_PHOTOS_DIR;

const ProfileCard = ({user, profiles}) =>{
  return (
    <>
      <Container className="profileCard" >
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" >
              <Avatar  src={(user.imageSrc || user.image) && BASE_URL+PHOTOS_DIR+`${user.image  ? user.image : user.imageSrc ? user.imageSrc : ""}`} />
            </Typography>
            <Typography variant="h4" align="center" style={{overflowWrap: "break-word"}} gutterBottom >
              {user.firstname} {user.lastname}
              {user.position && <p style={{fontWeight: '10', fontSize: "16px"}}>{user.position}</p>}
            </Typography>
            <Typography variant="body2" component={"div"}>
              {user.publicEmail && <MailIcon />} {user.publicEmail} <br />
              {user.tel && <ContactPhoneIcon />} {user.tel}  <br />
              {(user.addressName || user.place) && <Typography paragraph>
                <BusinessOutlinedIcon style= {{marginRight: "2%"}} />
                {user.place && user.place} {user.addressName && user.addressName} 
              </Typography>}
              {user.position && <Typography paragraph>{user.position}</Typography>}
              {user.keywords && <Badge sx={{mr:2}} badgeContent={user.keywords.length} color="secondary">
                <InterestsIcon/>
              </Badge>}
              {user.keywords && user.keywords.map( (el) => <Chip key={el} label={el} variant="outlined" />)}
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
 
export default ProfileCard;